<template>
  <div>
     <AdminNavBar/>
     <h1 class="purple-title text-center">Partner Organizations</h1>
     <div class="row">
        <div class="offset-md-1 col-md-2">
          <router-link to="/admin-dashboard"><button class="btn purple-btn mt-4">Back</button></router-link>
        </div>
        <div class="offset-md-1 col-md-4">
          <p class="text-center">
            <button v-if="!showAddForm" @click="showAddForm = true" class="btn purple-btn mt-4">Add New Organization</button>
            <button class="btn purple-btn mt-4" v-else @click="showAddForm = false">Details</button>
          </p>         
        </div>

      </div>
      <div class="row mt-4" v-if="!showAddForm">
        <div class="col-md-12">
              <AdminPartnerTable/>
        </div>
      </div>
      <div class="row mt-4" v-if="showAddForm">
        <div class="col-md-12">
            <div class="card shadow offset-md-3 col-md-7" style="padding-top:10px; padding-left:10px; padding-right:10px; padding-bottom:10px;">
            <div class="row">
               <div class="col-md-12">
              <p class="purple-title">Add new Organization</p>
            </div>
            </div>
            <div class="row">
              <div class="col-md-4 mt-4">
                 <label for="partner-name-input">Organization Name:</label>
                <input class="form-control" v-model="partner.name" type="text" id="partner-name-input"/>  
              </div>
              <div class="col-md-4 mt-4 offset-md-1">
                <label for="partner-regcode-input">Registration Code:</label>
                <input type="text" class="form-control" v-model="partner.regCode" id="partner-regcode-input">
              </div>
              <div class="col-md-9 mt-4">
                <label for="partner-address-input">Organization Address:</label>
                <input class="form-control" v-model="partner.address" type="text" id="partner-address-input"/>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4 mt-4">
                <label for="partner-city-input">Organization City:</label>
                <input class="form-control" v-model="partner.city" type="text" id="partner-city-input"/>
              </div>
              <div class="col-md-4 offset-md-1 mt-4">
                <label for="partner-state-input">Organization State:</label>
                <input class="form-control" v-model="partner.state" type="text" id="partner-state-input"/>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4 mt-4">
                <label for="partner-zipcode-input">Organization ZipCode:</label>
                <input  class="form-control" v-model="partner.zipCode" type="text" id="partner-zipcode-input"/>
              </div>
              <div class="col-md-4 offset-md-1 mt-4">
                <label for="partner-partnertype-input">Organization Type:</label>
                <input  class="form-control" v-model="partner.partnerType" type="text" id="editpartner-type-input"/>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4 mt-4">
                <label for="partner-contactname-input">Organization Contact Name:</label>
                <input class="form-control" v-model="partner.contactName" type="text" id="partner-contactname-input"/>
              </div>
              <div class="col-md-4 offset-md-1 mt-4">
                <label for="partner-contactphone1-input">Organization Contact Primary Phone:</label>
                <input class="form-control" v-model="partner.contactPhone1" type="text" id="partner-contactphone1-input"/> 
              </div>
            </div>
            <div class="row">
              <div class="col-md-4 mt-4">
                <label for="partner-contactphone2-input">Organization Contact Secondary Phone:</label>
                <input class="form-control" v-model="partner.contactPhone2" type="text" id="partner-contactphone2-input"/>
              </div>
              <div class="col-md-4 offset-md-1 mt-4">
                <label for="partner-contactemail-input">Organization Contact Email:</label>
                <input class="form-control" v-model="partner.contactEmail" type="email" id="partner-contactemail-input"/>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4 mt-4">
                <label for="partner-hasliab-input">Has Liability Insurance?:</label>
                <select class="form-control" v-model="partner.hasLiabInsurance" id="partner-hasliab-input">
                  <option value="true">Yes</option>
                  <option value="false">No</option>
                </select>
              </div>
              <div class="col-md-4 offset-md-1 mt-4">
                <label for="partner-hasworkcomp-input">Has Workers Comp Insurance?:</label>
                <select class="form-control" v-model="partner.hasWorkCompInsurance" id="partner-hasworkcomp-input">
                  <option value="true">Yes</option>
                  <option value="false">No</option>
                </select>
              </div>
            </div>
            <div class="row mb-4">
              <div class="col-md-4 mt-4">
                <label for="partner-hasworkcomex-input">Has Workers Comp Exemption?:</label>
                <select class="form-control" v-model="partner.hasWorkCompExemption" id="partner-hasworkcomex-input">
                  <option value="true">Yes</option>
                  <option value="false">No</option>
                </select>
              </div>
              <div class="col-md-4 mt-4 offset-md-1">
                <label for="partner-certifications-input">Organization Certifications:</label>
                <input class="form-control" type="text" v-model="partner.certifications" id="partner-certifications-input">
              </div>
            </div>
            <div class="row mt-4 mb-4">
              <div class="col-md-2 offset-md-10">
                <button class="btn purple-btn" @click="saveNewPartner()">Save</button>
              </div>
            </div>
           <div class="vld-parent">
                <loading :active.sync="isLoading" 
                :can-cancel="false" 
                
              ></loading>
               </div>     
        </div>
        </div>
      </div>
    </div>
</template>

<script>
import AdminNavBar from '../../components/Admin/AdminNavBar'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import {adminService} from '../../_services/index'
import AdminPartnerTable from '../../components/Admin/AdminPartnerTable'
export default {
  components:{
    AdminNavBar,
    Loading,
    AdminPartnerTable
  },
  data() {
    return{
          showAddForm: false,
          isLoading: false,
          partner: {
            name: "", address: "", city: "", state: "",
            zipCode: "", partnerType: "", contactName: "",
            contactPhone1: "", contactPhone2: "", contactEmail: "",
            hasLiabInsurance: "false", hasWorkCompInsurance: "false",
            hasWorkCompExemption: "false", certifications: "", regCode: ""
          }
    }
  },
  methods:{
    saveNewPartner(){
      this.partner.hasLiabInsurance = this.partner.hasLiabInsurance === "true" ? true : false;
      this.partner.hasWorkCompInsurance = this.partner.hasWorkCompInsurance === "true" ? true : false;
      this.partner.hasWorkCompExemption = this.partner.hasWorkCompExemption === "true" ? true : false;
      this.isLoading = true;
      adminService.addPartnerOrganization(this.partner)
      .then(()=> {
        this.isLoading =false;
        this.$toast.success("Successfully Created Organization!", {
                    position: 'bottom-center',
                    timeout: 5000,
                    hideProgressBar: true
                })
      }, () => {
        this.isLoading = false;
          this.$toast.error("Unable to create organization.", {
                        position: 'bottom-center',
                        timeout: 5000,
                        hideProgressBar: true
            })
      })

    }
  }
}
</script>

<style>

</style>